<template>
  <div class="w-100">
    <AsyncList
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="itemsFetch(false)"
    >
      <b-container fluid="xl py-4">
        <div class="mb-3">
          <div class="h3 mb-0">
            Оценка врача
          </div>
        </div>

        <div class="d-flex">
          <b-input
            v-model="filter.doctorName"
            placeholder="Введите ФИО врача"
            @keyup.native.enter="itemsFetch(true)"
          />

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="ml-2"
            @click="itemsFetch(true)"
          >
            <b-icon icon="search" />
          </b-button>
        </div>

        <div class="d-flex mt-2 align-items-center">
          <b-form-input
            v-model="filter.consultationId"
            size="sm"
            placeholder="Номер консультации"
            :style="{ width: '170px' }"
            @keyup.native.enter="itemsFetch(true)"
          />

          <b-form-input
            ref="inputDateFrom"
            v-model="filter.closeDateFrom"
            size="sm"
            :type="inputDateTypeFrom"
            placeholder="От"
            class="ml-2"
            :style="{ width: '150px' }"
            @click="onInputDateFromClick"
            @keyup.native.enter="itemsFetch(true)"
          />
          <b-form-input
            ref="inputDateTo"
            v-model="filter.closeDateTo"
            size="sm"
            :type="inputDateTypeTo"
            placeholder="До"
            class="ml-2"
            :style="{ width: '150px' }"
            @click="onInputDateToClick"
            @keyup.native.enter="itemsFetch(true)"
          />

          <b-form-select
            v-model="filter.isAgree"
            text-field="text"
            value-field="value"
            :options="evaluationStatuses"
            size="sm"
            class="ml-2 mr-2"
            :style="{ width: '150px' }"
            @change="itemsFetch(true)"
          />

          <b-button
            variant="outline-primary"
            class="ml-auto border-0"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            :style="{ flexShrink: '0' }"
            @click="resetFilter"
          >
            Сбросить фильтры
          </b-button>
        </div>

        <div
          v-if="items?.length"
          class="mt-4"
        >
          <DoctorEvaluationItem
            v-for="item in items"
            :key="item.id"
            :doctor-evaluation="item"
            class="mt-2"
          />
        </div>

        <div
          v-if="isLoading"
          class="mt-4"
        >
          <b-card
            v-for="item in 1"
            :key="item"
            class="mt-2 rounded border-0"
            body-class="p-3"
          >
            <b-skeleton
              animation="wave"
              width="17%"
            />
            <b-skeleton
              animation="wave"
              width="22%"
            />
            <b-skeleton
              animation="wave"
              width="25%"
            />
          </b-card>
        </div>

        <div
          v-if="!isLoading && !items?.length"
          class="mt-3 p-3"
        >
          Ничего не найдено
        </div>
      </b-container>
    </AsyncList>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

import AsyncList from '@/components/AsyncList';
import DoctorEvaluationItem from '@/components/Doctors/DoctorEvaluationItem';

export default {
  name: 'DoctorEvaluation',
  components: {
    AsyncList,
    DoctorEvaluationItem,
  },
  mixins: [mixinRoles],

  data: () => ({
    filter: {
      doctorName: '',
      consultationId: null,
      closeDateFrom: null,
      closeDateTo: null,
      isAgree: null,
    },
    inputDateTypeFrom: 'text',
    inputDateTypeTo: 'text',
    limit: 50,
    evaluationStatuses: [
      {
        text: 'Статус оценки',
        value: null,
      },
      {
        text: 'Согласен',
        value: true,
      },
      {
        text: 'Не согласен',
        value: false,
      },
    ],
  }),
  computed: {
    items() {
      return this.$store.state.Doctor.doctorsEvaluation.items;
    },
    isAllItemsLoaded() {
      return this.$store.state.Doctor.doctorsEvaluation.isAllItemsLoaded;
    },
    isLoading() {
      return this.$store.state.Doctor.doctorsEvaluation.isLoading;
    },
  },
  created() {
    this.itemsFetch(true);
    Bus.$on('doctor-evaluation:fetch', () => this.itemsFetch(true));
  },
  beforeDestroy() {
    Bus.$off('doctor-evaluation:fetch', () => this.itemsFetch(true));
  },
  methods: {
    async onInputDateFromClick() {
      this.inputDateTypeFrom = 'date';
      await this.$nextTick();
      this.$refs.inputDateFrom.focus();
    },
    async onInputDateToClick() {
      this.inputDateTypeTo = 'date';
      await this.$nextTick();
      this.$refs.inputDateTo.focus();
    },
    async itemsFetch(clear) {
      if (this.isLoading) return;

      if (!this.filter.closeDateFrom) this.inputDateTypeFrom = 'text';
      if (!this.filter.closeDateTo) this.inputDateTypeTo = 'text';

      await this.$store.dispatch(this.$types.DOCTORS_EVALUATION_FETCH, {
        clear,
        limit: this.limit,
        ...this.filter,
      });
    },
    resetFilter() {
      this.filter = {
        doctorName: '',
        consultationId: null,
        slotDate: null,
        isAgree: null,
      };

      this.itemsFetch(true);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
